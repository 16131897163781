// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaFacebook, FaSmileBeam, FaStar } from "react-icons/fa";
import { RiChatSmile3Fill } from "react-icons/ri";
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';
import { Helmet } from 'react-helmet';
import Carousel from './Carousel';
import CookieConsent from './CookieConsent';

function Home() {
  // Images pour le carousel
  const carouselImages = [
    './assets/images/IMG_1620.jpg',
    './assets/images/IMG_1624.PNG',
    './assets/images/IMG_1629.PNG',
    './assets/images/IMG_1628.PNG',
    './assets/images/IMG_1621.PNG',
    './assets/images/IMG_1622.PNG',
    './assets/images/IMG_1623.jpg',
    './assets/images/IMG_1625.jpg',
    './assets/images/IMG_1626.jpg',
    './assets/images/IMG_1612.jpg',
    './assets/images/IMG_1613.jpg',
    './assets/images/IMG_1614.jpg',
    './assets/images/IMG_1615.jpg',
    './assets/images/IMG_1616.jpg',
    './assets/images/IMG_1617.jpg',
    './assets/images/IMG_1618.jpg',
    './assets/images/IMG_1619.jpg'
  ];

  // Tableau d'avis
  const reviews = [
    {
      initials: 'RF',
      name: 'Rosaly F.',
      rating: 5,
      date: 'Le 08/02/2025',
      text: "Ravie d’avoir rencontré Mme MEZZI Anissa. J’ai tout de suite senti qu’elle savait de quoi elle parlait et qu’elle comprenait réellement les besoins de mon fils. Ça fait du bien d’être écoutée et rassurée. Je sais maintenant qu’il est, et sera, entre de bonnes mains. Merci."
    },
    {
      initials: 'MA',
      name: 'Malika A.',
      rating: 5,
      date: 'Le 07/02/2025',
      text: "Ma fille a pris confiance en elle dès la première séance grâce à la bienveillance d’Anissa. Elle est patiente et à l’écoute. Elle a fait preuve d’un grand professionnalisme en ciblant les besoins méthodologiques de Noémy, et travaille en collaboration avec l’équipe enseignante. Je la recommande les yeux fermés."
    },
    {
      initials: 'RG',
      name: 'Rabia G.',
      rating: 5,
      date: 'Le 07/02/2025',
      text: "Je recommande fortement Mme Mezzi Anissa pour son professionnalisme vraiment remarquable. Merci infiniment."
    },
    {
      initials: 'AB',
      name: 'Abdallah B.',
      rating: 5,
      date: 'Le 30/01/2025',
      text: "Madame Mezzi est très professionnelle et à l’écoute des enfants. Toujours prête à nous aider et à se déplacer pour les réunions dans les établissements scolaires. Vraiment, je suis contente du suivi par Mme Mezzi, car beaucoup de professeurs ne connaissent pas bien le TDH et le trouble de l’attention. Elle peut se déplacer et expliquer à l’équipe enseignante."
    },
  ];

  // Fonction pour afficher le nombre d'étoiles souhaité
  const renderStars = (count) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<FaStar key={i} style={{ color: '#FFD700', marginRight: '2px' }} />);
    }
    return stars;
  };

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Mezzi - Cabinet de Psychopédagogue à Pavillons-sous-Bois, Ile-de-France</title>
        <meta
          name="description"
          content="Cabinet de Psychopédagogue à Pavillons-sous-Bois, ile de france, 93 expert en soutien scolaire, accompagnement personnalisé et adaptation pour handicap."
        />
      </Helmet>

      <header id="head">
        <div className="container">
          <div className="row">
            <h1 className="lead">Réussir ensemble avec la psychopédagogie.</h1>
            <p className="tagline">Psychopédagogie : Découvrir, Apprendre, Réussir.</p>
            <p>
              <Link
                className="btn btn-default btn-lg btn-inverse-hover"
                to="/activite"
                role="button"
              >
                Activité
              </Link>{' '}
              <a
                className="btn btn-primary btn-lg btn-inverse-hover"
                style={{ fontWeight: 'bold', padding: '15px 30px' }}
                href="https://www.resalib.fr/praticien/95951-anissa-mezzi-psychopedagogue-les-pavillons-sous-bois"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Prendre rendez-vous
              </a>
            </p>
          </div>
        </div>
      </header>

      <div
        className="container text-center"
        style={{ minHeight: '450px', padding: '60px 0px' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h3
            style={{
              color: 'rgb(102, 0, 153)',
              fontWeight: 'bold',
              textDecoration: 'none',
              fontSize: '3.5rem'
            }}
          >
            Anissa MEZZI <br />
            <hr
              style={{
                width: '50px',
                margin: '20px auto',
                borderTop: '2px solid rgb(102, 0, 153)'
              }}
            />
            Psychopédagogue à Pavillons-Sous-Bois
          </h3>
        </div>

        <h2 className="thin" style={{ marginBottom: '20px' }}>
          À Pavillons-sous-Bois, votre épanouissement est notre priorité grâce à une approche psychopédagogique personnalisée.
        </h2>
        <p
          className="text-muted"
          style={{ maxWidth: '800px', margin: '0 auto', marginBottom: '20px' }}
        >
          Nous sommes à votre écoute pour toute question ou prise de rendez-vous. Notre équipe de spécialistes en psychopédagogie est dédiée à vous fournir le soutien et l'accompagnement nécessaires pour surmonter les défis d'apprentissage et de développement personnel.
        </p>

        <p style={{ marginTop: '80px', textAlign: 'center' }}>
          <a
            className="btn btn-primary btn-lg"
            style={{
              fontWeight: 'bold',
              padding: '20px 40px',
              fontSize: '1.8rem',
              borderRadius: '50px',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
              backgroundImage: 'linear-gradient(45deg, #6a11cb, #2575fc)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease'
            }}
            href="https://www.resalib.fr/praticien/95951-anissa-mezzi-psychopedagogue-les-pavillons-sous-bois"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 12px 24px rgba(0,0,0,0.3)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
            }}
          >
            <FaCalendarAlt style={{ marginRight: '10px' }} />
            Prendre rendez-vous
          </a>
        </p>
      </div>

      <Carousel images={carouselImages} />

      <p
        style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          color: '#555',
          maxWidth: '900px',
          margin: '0 auto'
        }}
      >
        Dans notre cabinet, nous mettons l’accent sur une écoute bienveillante et une approche holistique,
        alliant méthodes d’apprentissage innovantes et techniques de gestion des émotions. Nous sommes
        convaincus que chaque apprenant possède des ressources uniques qu’il s’agit de révéler et de
        cultiver. Notre accompagnement se veut ainsi complet, afin de renforcer la confiance en soi,
        d’encourager l’autonomie et de favoriser une meilleure intégration scolaire et sociale.
      </p>

      {/* Section "Ils me font confiance" */}
      <div
        className="missions"
        style={{
          marginTop: '40px',
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '500px'
        }}
      >
        <h3
          style={{
            color: 'rgb(102, 0, 153)',
            marginBottom: '20px',
            fontWeight: 'bold',
            fontSize: '3.5rem'
          }}
        >
          Ils me font confiance
          <FaSmileBeam
            style={{
              fontSize: '2.5rem',
              color: 'rgb(102, 0, 153)',
              marginLeft: '10px'
            }}
          />
        </h3>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '40px',
            flexWrap: 'wrap',
            alignItems: 'stretch'
          }}
        >
          {/* Panneau pour SAINTE LOUISE DE MARILLAC */}
          <a
            href="https://www.groupescolaire-saintelouisedm.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '30px',
                minWidth: '280px',
                width: '400px',
                height: '400px',
                flex: '1',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.2s, fontWeight 0.2s'
              }}
            >
              <div>
                <h4 style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '1.5rem' }}>
                  SAINTE LOUISE DE MARILLAC
                </h4>
                <hr
                  style={{
                    width: '50px',
                    border: '1px solid #ccc',
                    marginBottom: '15px',
                    margin: '0 auto'
                  }}
                />
                <p style={{ marginBottom: '10px', fontSize: '1.5rem' }}>
                  Conseillère pédagogique en inclusion scolaire
                </p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <div
                  style={{
                    width: '280px',
                    height: '180px',
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src="./assets/images/logo-gslm-2021.avif"
                    alt="psychopédagogue"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              </div>
            </div>
          </a>

          {/* Panneau pour ECOLE TREBOTZASSERE */}
          <a
            href="https://tebrotzassere.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '30px',
                minWidth: '280px',
                width: '400px',
                height: '400px',
                flex: '1',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.2s, fontWeight 0.2s'
              }}
            >
              <div>
                <h4 style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '1.5rem' }}>
                  ECOLE TREBOTZASSERE
                </h4>
                <hr
                  style={{
                    width: '50px',
                    border: '1px solid #ccc',
                    marginBottom: '15px',
                    margin: '0 auto'
                  }}
                />
                <p style={{ marginBottom: '10px', fontSize: '1.5rem' }}>
                  Psychopédagogue et conseillère pédagogique en inclusion scolaire
                </p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <div
                  style={{
                    width: '280px',
                    height: '180px',
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src="./assets/images/Logo_Ecole_Tebrotzassère.jpg"
                    alt="psychopédagogue"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* Section Avis */}
      <div className="container" style={{ marginTop: '40px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h3>
            <a
              href="https://www.resalib.fr/praticien/95951-anissa-mezzi-psychopedagogue-les-pavillons-sous-bois#reviews"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: 'rgb(102, 0, 153)',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: '3.5rem'
              }}
            >
              Voir tous les avis
              <RiChatSmile3Fill style={{ marginLeft: '8px', fontSize: '2.0rem' }} />
            </a>
          </h3>
        </div>
        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
          {reviews.map((review, index) => (
            <div className="col-md-3 col-sm-6" key={index} style={{ marginBottom: '30px' }}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  padding: '20px',
                  textAlign: 'center',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                  height: '100%'
                }}
              >
                <h5
                  style={{
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '2.0rem'
                  }}
                >
                  {review.name}
                </h5>
                <div style={{ marginBottom: '10px', fontSize: '2.5rem' }}>
                  {renderStars(review.rating)}
                </div>
                <div style={{ fontSize: '0.9rem', color: '#555', marginBottom: '15px' }}>
                  {review.date}
                </div>
                <p style={{ lineHeight: '1.4', color: '#444', fontSize: '1.5rem' }}>
                  {review.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section Jumbotron */}
      <div className="jumbotron top-space">
        <div className="container">
          <h3 className="text-center thin">
            Pourquoi choisir la psychopédagogie pour transformer l'apprentissage ?
          </h3>
          <div className="row">
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption">
                <h4>
                  <i className="fa fa-heart fa-5"></i> Approche Personnalisée
                </h4>
              </div>
              <div className="h-body text-center">
                <p>
                  La psychopédagogie permet de comprendre les besoins individuels de chaque apprenant.
                  En combinant des techniques psychologiques et pédagogiques, elle offre une approche
                  sur mesure qui respecte le rythme et le style d'apprentissage de chacun. Cette
                  personnalisation favorise une meilleure assimilation des connaissances et un
                  développement optimal des compétences.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption">
                <h4>
                  <i className="fa fa-flash fa-5"></i> Renforcement de la Motivation
                </h4>
              </div>
              <div className="h-body text-center">
                <p>
                  La psychopédagogie aide à identifier et à lever les barrières psychologiques
                  à l'apprentissage, telles que l'anxiété ou le manque de confiance en soi.
                  En travaillant sur ces aspects, elle renforce la motivation et l'engagement
                  des apprenants, ce qui se traduit par une plus grande persévérance et de
                  meilleurs résultats scolaires.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption">
                <h4>
                  <i className="fa fa-cogs fa-5"></i> Compétences Polyvalentes
                </h4>
              </div>
              <div className="h-body text-center">
                <p>
                  Au-delà des connaissances académiques, la psychopédagogie se concentre sur
                  le développement de compétences transversales essentielles, telles que la
                  gestion du stress, la résolution de problèmes et la pensée critique.
                  Ces compétences sont cruciales pour la réussite tant scolaire que professionnelle,
                  et contribuent à former des individus adaptables et résilients.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption">
                <h4>
                  <i className="fa fa-smile-o fa-5"></i> Promotion du Bien-être
                </h4>
              </div>
              <div className="h-body text-center">
                <p>
                  L'intégration de la psychopédagogie dans le processus d'apprentissage
                  contribue également au bien-être global des apprenants. En prenant
                  en compte leurs émotions et en offrant des stratégies pour gérer
                  les difficultés, elle crée un environnement d'apprentissage plus sain et plus agréable.
                  Un apprenant qui se sent bien est plus enclin à s'investir et à réussir dans ses études.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section Parcours */}
      <div className="container">
        <h2 className="text-center top-space">Consulter notre Parcours</h2>
        <div className="fb-section" style={{ textAlign: 'center' }}>
          <h3>Rejoignez-nous notre association Troubles Et Nous sur Facebook</h3>
          <br />
          <a
            href="https://www.facebook.com/people/Troubles-Et-Nous/100070950050238/"
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook style={{ marginRight: '10px' }} />
            Troubles Et Nous
          </a>
          <div
            className="fb-like"
            data-href="https://www.facebook.com/people/Troubles-Et-Nous/100070950050238/"
            data-width=""
            data-layout="standard"
            data-action="like"
            data-size="large"
            data-share="true"
          ></div>
        </div>
        <div className="jumbotron top-space">
          <h4>
            Le parcours d'un psychopédagogue est riche et diversifié, intégrant des connaissances
            approfondies en psychologie et en pédagogie.
          </h4>
          <p className="text-right">
            <Link className="btn btn-primary btn-large" to="/parcours">
              voir plus
            </Link>
          </p>
        </div>
      </div>

      <Footer />
      <CookieConsent />
    </>
  );
}

export default Home;
