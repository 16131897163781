import ReactGA from "react-ga4";

// Initialisation de Google Analytics
export const initGA = () => {
  ReactGA.initialize("G-1J6MEZYFF6"); // Remplacez par votre ID de suivi GA4
};

// Suivi des pages
export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Suivi d'événements personnalisés
export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};
