import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { initGA, logPageView } from "./utils/analytics"; // Import GA
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import Cookies from "./pages/Cookies";
import Activite from "./pages/Activite";
import Parcours from "./pages/Parcours";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery/dist/jquery.min.js";

import "./assets/css/font-awesome.min.css";
import "./assets/css/bootstrap-theme.css";
import "./assets/css/main.css";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Enregistre chaque changement de page
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    initGA(); // Initialisation au premier rendu
  }, []);

  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/activite" element={<Activite />} />
        <Route path="/parcours" element={<Parcours />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
