// Cookies.js
import React from 'react';
import Navbar from './basic/Navbar';
import Footer from './basic/Footer';

function Cookies() {

  return (
    <>
      <Navbar />
      <div className="container" style={{ padding: '20px', marginTop: '20px' }}>
        <h1>Politique d'Utilisation des Cookies</h1>
        <p>
          Ce site utilise des cookies afin d'améliorer votre expérience de navigation, 
          d'analyser le trafic et de personnaliser le contenu. Vous trouverez ci-dessous 
          toutes les informations nécessaires concernant l'utilisation des cookies, conformément aux exigences légales.
        </p>

        <h2>Qu'est-ce qu'un cookie ?</h2>
        <p>
          Un cookie est un petit fichier texte déposé sur votre appareil lors de la visite d'un site web. 
          Il permet de mémoriser vos préférences et de collecter des informations sur votre navigation 
          pour améliorer et personnaliser votre expérience.
        </p>

        <h2>Les cookies utilisés sur ce site</h2>
        <ul>
          <li>
            <strong>Cookies strictement nécessaires :</strong> Ces cookies sont essentiels 
            au bon fonctionnement du site et ne peuvent pas être désactivés. 
            Ils incluent par exemple les cookies de session permettant de conserver vos préférences.
          </li>
          <li>
            <strong>Cookies Google Analytics :</strong> Ces cookies collectent des données anonymes 
            telles que les pages visitées, la durée de la visite et le comportement de navigation. 
            Ces informations nous aident à analyser le trafic et à améliorer le contenu et les performances du site.
          </li>
        </ul>

        <h2>Gestion et désactivation des cookies</h2>
        <p>
          Lors de votre première visite sur ce site, un bandeau vous informe de l'utilisation des cookies 
          et vous permet d'accepter ou de refuser leur utilisation. 
        
          Vous pouvez également gérer ou désactiver les cookies via les paramètres de votre navigateur. 
          Notez que la désactivation de certains cookies peut altérer votre expérience sur notre site.
        </p>
        <p>
          Pour plus d'informations sur la gestion des cookies, veuillez consulter la documentation 
          de votre navigateur ou vous rendre sur le site de la CNIL (Commission Nationale de l'Informatique et des Libertés) à l'adresse&nbsp;
          <a href="https://www.cnil.fr" target="_blank" rel="noopener noreferrer">
            www.cnil.fr
          </a>.
        </p>

        <h2>Durée de conservation des cookies</h2>
        <p>
          Les cookies utilisés sur ce site ont une durée de conservation de 13 mois maximum, 
          conformément aux recommandations de la CNIL. Après cette période, votre consentement 
          sera à nouveau requis.
        </p>

        <h2>Conformité légale</h2>
        <p>
          Conformément au Règlement Général sur la Protection des Données (RGPD) et à la directive ePrivacy, 
          ce site vous informe de l'utilisation des cookies et sollicite votre consentement préalable. 
          Vous pouvez retirer votre consentement à tout moment en modifiant les paramètres de votre navigateur 
          ou via le bouton "Gérer mes cookies".
        </p>

        <h2>Mise à jour de la politique</h2>
        <p>
          Cette politique d'utilisation des cookies a été mise à jour le : 07/03/2025. 
          Nous nous réservons le droit de la modifier afin de l'adapter aux évolutions légales et technologiques.
        </p>

      </div>
      <Footer />
    </>
  );
}

export default Cookies;
