// Carousel.js
import React from 'react';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

// Flèche suivante personnalisée
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1
      }}
      onClick={onClick}
    >
      <FaArrowRight style={{ fontSize: "24px", color: "black" }} />
    </div>
  );
}

// Flèche précédente personnalisée
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1
      }}
      onClick={onClick}
    >
      <FaArrowLeft style={{ fontSize: "24px", color: "black" }} />
    </div>
  );
}

function Carousel({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,        // 3 images visibles sur grand écran
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // Ajout de la configuration responsive
    responsive: [
      {
        breakpoint: 768, // Largeur max (en px) pour activer ces settings
        settings: {
          slidesToShow: 1,  // 1 image visible en dessous de 768px
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div style={{ width: '100%', marginBottom: '50px' }}>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} style={{ padding: "0 1.5px" }}>
            <img
              src={img}
              alt={`carousel-${index}`}
              style={{
                width: '100%',
                height: '650px',
                objectFit: 'cover'
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
