import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Vérifie si le consentement aux cookies existe et s'il est encore valide
    const consentCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('cookieConsent='));

    if (!consentCookie) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 13); // Expiration dans 13 mois
    document.cookie = `cookieConsent=true; expires=${expirationDate.toUTCString()}; path=/; Secure; SameSite=Lax`;
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div 
      className="fixed-bottom bg-light p-3 border-top" 
      style={{ boxShadow: '0 -2px 5px rgba(0,0,0,0.1)' }}
    >
      <div className="container d-flex justify-content-between align-items-center">
        <small>
          Ce site utilise des cookies pour améliorer votre expérience.{' '}
          <Link to="/cookies">En savoir plus</Link>.
        </small>
        <button className="btn btn-primary btn-sm" onClick={handleAccept}>
          J&apos;accepte
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
